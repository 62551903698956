
import React from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import '../../App.css';


export default function Gallery(){
  const images = ['main-logo.png', 'roadmarking.jpg'].map((image) => ({src: `/images/${image}`}));

  return(
    <div className="container galeria">
      <h1 className="text-center">Galería</h1>
      <Carousel images={images} className="carousel-civial"/>
    </div>
  );
}