import './App.css';
// import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './components/pages/Home';
import Services from './components/pages/Services';
import Gallery from './components/pages/Gallery';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/servicios' exact component={Services} />
          <Route path='/galeria' exact component={Gallery} />
        </Switch>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
