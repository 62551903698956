import React from 'react';
import './Service.css';

function Service(props) {
	
	return (
		<>
			<div className={`service-container ${props.type !== 'home' ? '' : 'no-desc '}`}>
					<img src = {props.src} alt = {props.alt} className="service-image" />
					<div className="services-text-container">
						<h4 className={props.type === 'services-page' ? '' : 'text-center'}>{props.title}</h4>
						{props.type === 'services-page' && <p>{props.description}</p>}
					</div>
			</div>
		</>
	);
}

export default Service;