import React from 'react';
import './Button.css';
import {Link} from 'react-router-dom';

const buttonStyles = ['btn-primary', 'btn-outline'];
const buttonSizes = ['btn-medium', 'btn-large', 'btn-long']

export const Button = ({children, type, onClick, buttonStyle, buttonSize, withLink, linkClass}) =>{

  const checkButtonStyle =  buttonStyles.includes(buttonStyle) ? buttonStyle : buttonStyles[0];
  const checkButtonSize = buttonSizes.includes(buttonSize) ? buttonSize : buttonSizes[0];

  if(withLink){
    return(
      <Link to={withLink} className={linkClass && linkClass}> 
        <button className={`btn ${checkButtonStyle} ${checkButtonSize}`} onClick={onClick} type={type}>{children}</button>
      </Link>
    );
  }else{
    return(
    <>
      <button className={`btn ${checkButtonStyle} ${checkButtonSize}`} onClick={onClick} type={type}>{children}</button>
    </>
    );
  }
};

export default Button;