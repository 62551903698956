import React from 'react';
import './Footer.css';

function Footer(){
  return(
    <footer className="container">
      <div className="info-container">
        <div className="contact">
          <h3>Contacto</h3>
          <a href="mailto:civialcr@gmail.com">civialcr@gmail.com</a>
          {/* <a href="tel:83940816 ">8394-0816 </a>
          <a href="tel:83017667">8301-7667</a> */}
        </div>
        <div className="menu">
          <h3>Menu</h3>
          <a href="/">Home</a>
          <a href="/servicios">Servicios</a>
          <a href="/galeria">Galeria</a>
        </div>
      </div>
      <div className="copyright-container">
        <a href="/">
          <img src="/images/main-logo.png" alt="logo" className="logo-footer"/></a>
        <p className="text-center">Copyright &copy;2021. CivialSA Todos los derechos reservados.</p>
      </div>
    </footer>
  );
}

export default Footer;