
import React from 'react';
import '../../App.css';
import ServicesComponent from '../ServicesComponent';


export default function Services(){
  return(
    <div className="container">
      <h1 className="text-center services-title">Servicios</h1>
      <div className="services-container">
        <ServicesComponent type="services-page"></ServicesComponent>
      </div>
    </div>
  );
}