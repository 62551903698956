import React from 'react';
import './HeroSection.css';
import Button from './Button';

function HeroSection(){
  return(
    <div className="hero-container container">
      <img src="/images/main-logo.png" alt="logo" className="logo-hero"/>
      <h2 className="hero-sub-text">Demarcación y seguridad vial</h2>
      <Button className="contact-button" buttonStyle='btn-outline' buttonSize='btn-large' withLink='/'>Contáctenos</Button>
    </div>
  );
}

export default HeroSection;