import '../../App.css';
import HeroSection from '../HeroSection';
import Contact from '../Contact';
import ServicesComponent from '../ServicesComponent';



function Home(){
  return(
    <>
    <HeroSection/>
    <ServicesComponent type="home"/>
    <Contact/>
    </>
  );
}


export default Home;