import React from 'react';
import './Contact.css';
import Button from './Button';

export default function Contact() {
  return (
    <section className="container contact-main-container" id="contactContainer" onSubmit='submit'>
      <h2>Contáctenos</h2>
      <form className="contact-form-container" name="contact" method="POST" data-netlify="true">
        <div className="contact-form-inputs-containers">
          <label>Nombre completo</label>
          <input type="text" name="name"/>
          <label>Correo electrónico</label>
          <input type="email" name="email" />
          <label>Número telefónico</label>
          <input type="number" name="phone" />
          <label>Mensaje</label>
          <textarea name="message" rows="6"></textarea>
          <Button className="contact-button" buttonStyle='btn-primary' buttonSize='btn-long' type='submit'>Enviar</Button>
        </div>
      </form>
    </section>
  )
}
