import emailjs from 'emailjs-com';

export const services = [
  {
    alt: 'test',
    title: 'Colocacion de captaluces',
    src: './images/roadmarking.jpg'
  },
  {
    alt: 'test',
    title: 'Señalamiento Horizontal',
    src: './images/senalamientoHorizontal.jpg'
  },
  {
    alt: 'test',
    title: 'Señalamiento Vertical',
    src: './images/senalamientoVertical.jpg'
  },
  {
    alt: 'test',
    title: 'Barreras de Flex Beam',
    src: './images/barreraFlexbeam.jpg'
  }
];

export const sendEmail = e => {
  e.preventDefault();
  emailjs.sendForm('service_u9eau9h', 'template_5c02ufe', e.target, 'user_4mVi3yFb9JnthLvM76tGj')
    .then((result) => {
      console.log(result.text);
      e.target.reset();
    }, (error) => {
      console.log(error.text);
    });
};