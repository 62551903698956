import React from 'react';
import '../App.css';
import Service from './Service';
import Button from './Button';
import {services} from './Utils';
import './ServicesComponent.css';



export default function ServicesComponent(props){
  const buttonText = props.type === 'home' ? 'Ver más' : 'Contáctenos';
	const linkTo = props.type === 'home' ? 'servicios' : '/';

  return(
    <div className={`services container ${props.type === 'home' ? 'gray-bg full-page-height' : 'pt-0'}`}>
      {props.type !=='services-page' && <h2 className="text-center services-title">Servicios</h2>}
      <div className="services-container">
        {services.map((service, index) => <Service alt={service.alt} title={service.title} description={service.description} src={service.src} type={props.type} key={index}></Service>)}
      </div>
      <Button linkClass="services-link" buttonStyle='btn-primary' buttonSize='btn-large' withLink={linkTo}>{buttonText}</Button>
    </div>
  );
}