import React, {useState} from 'react';
import './Navbar.css';
import {Link} from 'react-router-dom';

function Navbar(){
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  return(
    <>
    <nav className="navbar">
      <div className=" container navbar-container">
        <Link to="/" className="navbar-logo">
          <img src="/images/main-logo.png" alt="logo" className="logo"/>
        </Link>
        <div className={`navbar-menu-icon ${click ? 'rotate': ''}`} onClick={handleClick}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
          {/* <span className={`close-menu ${click ? 'd-block' : 'd-none'}`}>&#10006;</span> */}
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <Link to="/" className="nav-links" onClick={closeMobileMenu}>Home</Link>
          <Link to="/servicios" className="nav-links" onClick={closeMobileMenu}>Servicios</Link>
          <Link to="/galeria" className="nav-links" onClick={closeMobileMenu}>Galería</Link>
        </ul>
      </div>
    </nav>
  </>
  );
}

export default Navbar;